<template>
  <div id="wishlist">
    <SfSidebar
      :visible="isWishlistSidebarOpen"
      :button="false"
      title=" "
      class="sidebar sf-sidebar--right"
      @close="toggleWishlistSidebar"
    >
      <template #circle-icon="{ close }">
        <div
          class="close-icon"
          @click="close"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L17 17"
              stroke="#171717"
              stroke-width="2"
            />
            <path
              d="M17 1L1 17"
              stroke="#171717"
              stroke-width="2"
            />
          </svg>
        </div>
      </template>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="wishlistItems && wishlistItems.length > 0"
          key="my-wishlist"
          class="my-wishlist"
        >
          <div class="my-wishlist__total-items">
            {{ $t('Wishlist ') }} ({{ totalItems }})
          </div>
          <div class="collected-product-list">
            <div
              v-for="(wishlistItem, i) in wishlistItems"
              :key="i"
              class="wishlist-item-list"
              @click:remove="
                removeItemFromWishlist({ product: wishlistItem })
              "
            >
              <div class="wishlist-item-media">
                <figure>
                  <img
                    :src="
                      wishlistGetters.getItemImage(wishlistItem)
                        ? wishlistGetters.getItemImage(wishlistItem)
                        : ''
                    "
                    :alt="wishlistGetters.getItemName(wishlistItem)"
                  >
                </figure>
              </div>
              <div class="wishlist-item-details">
                <div class="wishlist-item-content">
                  <nuxt-link
                    :to="localePath(buildProductUrl(wishlistItem.product))"
                    @click.native="toggleWishlistSidebar"
                  >
                    <h3 class="wishlist-item-name">
                      {{ wishlistGetters.getItemName(wishlistItem) }}
                    </h3>
                  </nuxt-link>
                  <div
                    v-if="wishlistGetters.getItemPrice(wishlistItem).special"
                    class="wishlist-item-price"
                  >
                    <p class="wishlist-item-price__special">
                      {{
                        wishlistGetters.getItemPrice(wishlistItem).special &&
                          $n(
                            wishlistGetters.getItemPrice(wishlistItem).special,
                            'currency'
                          )
                      }}
                    </p>
                    <p class="wishlist-item-price__old">
                      {{
                        $n(
                          wishlistGetters.getItemPrice(wishlistItem).regular,
                          'currency'
                        )
                      }}
                    </p>
                  </div>
                  <div
                    v-else
                    class="wishlist-item-price"
                  >
                    <p class="wishlist-item-price__special">
                      {{
                        $n(
                          wishlistGetters.getItemPrice(wishlistItem).regular,
                          'currency'
                        )
                      }}
                    </p>
                  </div>
                  <div class="wishlist-item-configuration">
                    <div v-if="isConfigured(wishlistItem)">
                      <SfProperty
                        v-for="option in getProductConfiguration(wishlistItem)"
                        :key="option.uid"
                        :name="$t(option.label)"
                        :value="option.value"
                      />
                    </div>
                  </div>
                </div>
                <div class="wishlist-item-button">
                  <div class="wishlist-item-actions">
                    <a
                      v-if="canAddToCart(wishlistItem)"
                      @click="addItemToCart(wishlistItem)"
                    >
                      <SfIcon>
                        <nuxt-img
                          width="20"
                          height="14"
                          :src="require('../assets/images/header/Logo_Carrello.svg')"
                          alt="choose product configuration icon"
                        />
                      </SfIcon>
                    </a>
                    <a
                      v-else-if="
                        !isConfigured(wishlistItem) &&
                          isConfigurable(wishlistItem)
                      "
                      :href="localePath(buildProductUrl(wishlistItem.product))"
                    >
                      <SfIcon>
                        <nuxt-img
                          width="20"
                          height="14"
                          src="/icons/pencil-icon.svg"
                          alt="choose product configuration icon"
                        />
                      </SfIcon>
                    </a>
                    <span
                      v-else
                      class="out-of-stock-label"
                    >{{
                      $t('Out of stock')
                    }}</span>

                    <a
                      @click="
                        removeItemFromWishlist({
                          product: wishlistItem,
                        })
                      "
                    >
                      <svg
                        height="20"
                        width="14"
                      >
                        <rect
                          x="5"
                          y="1"
                          width="5"
                          height="1"
                        />
                        <rect
                          x="1"
                          y="2"
                          width="13"
                          height="3"
                        />
                        <rect
                          x="2"
                          y="6"
                          width="11"
                          height="15"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          key="empty-wishlist"
          class="empty-wishlist"
        >
          <div class="empty-wishlist__banner">
            <nuxt-img
              :src="require('../assets/images/header/Lazzari-Preferiti.svg')"
              alt="Empty wishlist"
              class="empty-wishlist__icon"
              format="webp"
              width="211"
              height="143"
              preload
              loading="lazy"
            />
            <SfHeading
              :title="$t('La tua wishlist è vuota')"
              :description="
                $t(
                  'Sembra che tu non abbia ancora aggiunto alcun articolo alla tua lista.'
                )
              "
              class="empty-wishlist__label"
            />
          </div>
        </div>
      </transition>
      <template #content-bottom>
        <SfButton
          class="sf-button--full-width color-secondary"
          @click="toggleWishlistSidebar"
        >
          {{ $t('Go back shopping') }}
        </SfButton>
      </template>
    </SfSidebar>
  </div>
</template>
<script>
import {
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  SfIcon,
} from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import {
  useUser,
  wishlistGetters,
  productGetters,
} from '@vue-storefront/gemini';
import {
  useUiState,
  useImage,
  useUrlHelpers,
  useWishlist,
} from '~/composables';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    SfSidebar,
    SfButton,
    SfHeading,
    SfProperty,
    SfIcon,
  },
  setup() {
    const {
      addItemToCart,
      wishlist,
      removeItemFromWishlist,
      updateItem,
    } = useWishlist();
    const { buildProductUrl } = useUrlHelpers();
    const { isWishlistSidebarOpen, toggleWishlistSidebar } = useUiState();
    const { isAuthenticated } = useUser();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const wishlistItems = computed(() => wishlistGetters.getItems(wishlist.value));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const totals = computed(() => wishlistGetters.getTotals(wishlist.value));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const totalItems = computed(() => wishlistItems?.value?.length);

    const getAttributes = (product) => product?.product?.configurable_options || [];

    const { getGeminiImage, imageSizes } = useImage();

    const changeItemQuantity = (wishlistItem, quantity) => {
      if (quantity > 0) {
        updateItem({
          product: wishlistItem.product,
          quantity,
        });
      } else {
        removeItemFromWishlist({ product: wishlistItem });
      }
    };

    const isConfigurable = (wishlistItem) => wishlistItem.product?.vsf_typename === 'ConfigurableProduct';

    const isConfigured = (wishlistItem) => wishlistItem?.item_grn?.split('/').length > 1;

    const getProductConfiguration = (wishlistItem) => {
      if (!isConfigured(wishlistItem)) {
        return [];
      }
      const productGrn = wishlistItem.item_grn;
      const configurableOptions = wishlistItem.product?.configurable_options || [];
      const variant = wishlistItem.product?.variants?.find(
        (item) => item.product.uid === productGrn,
      );

      const optionValues = variant
        ? variant.option_uids.map((item) => configurableOptions
          .map((option) => {
            const value = option.values.find(
              (optionValue) => optionValue.uid === item,
            );
            if (value) {
              return {
                uid: value.uid,
                label: option.label,
                value: value.label,
                swatches: value.swatches,
              };
            }
            return null;
          })
          .find((option) => option?.uid === item))
        : [];
      return optionValues.length > 0 ? optionValues : [];
    };
    const canAddToCart = (wishlistItem) => {
      if (isConfigurable(wishlistItem)) {
        return (
          (isConfigured(wishlistItem)
            && wishlistItem.product?.stock_status === 'IN_STOCK')
          || (isConfigured(wishlistItem) && wishlistItem.product.is_virtual)
        );
      }

      const inStock = wishlistItem.product?.stock_status || '';
      const stockLeft = wishlistItem.product?.only_x_left_in_stock === null
        ? true
        : wishlistItem.qty <= wishlistItem.product?.only_x_left_in_stock;
      return inStock && stockLeft;
    };

    return {
      getAttributes,
      isAuthenticated,
      isWishlistSidebarOpen,
      wishlistItems,
      removeItemFromWishlist,
      toggleWishlistSidebar,
      totalItems,
      totals,
      wishlistGetters,
      wishlist,
      productGetters,
      getGeminiImage,
      imageSizes,
      buildProductUrl,
      changeItemQuantity,
      isConfigurable,
      canAddToCart,
      isConfigured,
      getProductConfiguration,
      addItemToCart,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar {
  --sidebar-z-index: 100;
  --overlay-z-index: 100;
  --sidebar-top-padding: 3.75rem 4.0625rem 0 4.0625rem;
  --sidebar-content-padding: 0 4.0625rem;
  --sidebar-bottom-padding: 1.5rem 4.0625rem;
}
.close-icon {
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
@include for-mobile {
  .close-icon {
    display: none;
  }
}

@media (max-width: 1023px) {
  .close-icon {
    display: block;
    top: 15px;
    right: 20px;
  }
  .sidebar {
    --sidebar-top-padding: 1.5625rem 1.4375rem 0 1.4375rem;
    --sidebar-content-padding: 0 1.4375rem;
    --sidebar-bottom-padding: 1rem 1.4375rem;
  }
}

@media (min-width: 1024px) {
  .close-icon {
    right: 65px;
    top: 60px;
  }
}

.sf-sidebar__bottom .sf-button.color-secondary {
  background: #212a24;
  border: 1px solid #707070;
  color: #fff;
  font-family: 'Spartan';
  font-size: 14px;
  font-weight: 700;
  height: 65px;
  letter-spacing: 1px;
  line-height: 65px;
  text-transform: none;
  padding: 0;
  &:hover,
  :active,
  :focus {
    background-color: rgba(34, 42, 36, 0.7);
    color: #fff;
    text-decoration-line: none;
  }
}

.my-wishlist {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__total-items {
    border-bottom: 1px solid #c6c8c6;
    padding-bottom: 20px;
    margin: 0 0 24px;
    color: #222a24;
    font-family: 'Spartan';
    font-size: 16px;
    letter-spacing: 0;
    word-spacing: 0.5rem;
    line-height: 23px;
  }
  &__total-price {
    --property-name-font-size: var(--font-size--xl);
    --price-font-size: var(--font-size--xl);
    margin: 0 0 var(--spacer-xl) 0;
    &-label {
      font: var(--font-weight--normal) var(--font-size--2xl) / 1.6
        var(--font-family--secondary);
      color: var(--c-link);
    }
  }
}
.empty-wishlist {
  display: flex;
  flex: 1;
  flex-direction: column;
  &__banner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1.5rem;
  }
  &__label {
    .sf-heading__title.h2 {
      color: #d28571;
      font-family: 'Frank Ruhl Libre';
      font-size: 30px;
      text-align: center;
    }
    .sf-heading__description {
      font-family: 'Spartan';
    }
  }
  &__label {
    --heading-description-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-color: var(--c-primary);
    --heading-title-font-weight: var(--font-weight--semibold);
    @include for-desktop {
      --heading-title-font-size: var(--font-size--xl);
      --heading-title-margin: 0 0 var(--spacer-sm) 0;
    }
  }
  &__icon {
    margin-bottom: 1.25rem;
    height: 3.125rem;
    width: 3.125rem;
  }
}
.heading {
  &__wrapper {
    --heading-title-color: var(--c-link);
    --heading-title-font-weight: var(--font-weight--semibold);
    display: flex;
    justify-content: space-between;
  }
}

.sidebar-bottom {
  margin: auto 0 0 0;
}
</style>
<style lang="scss">
#wishlist {
  .sf-sidebar {
    &__aside {
      background-color: #fcf1ee;
      width: 35.625rem;
      .sf-bar {
        background-color: #fcf1ee;
        @include for-mobile {
          border-bottom: 1px solid #e9ecf1;
          height: 3.375rem;
        }
        .sf-icon {
          display: none;
        }
      }
      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
  .collected-product-list {
    flex: 1;
    .wishlist-item-list {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 1023px) {
        flex-wrap: nowrap;
      }
      margin-bottom: 1.5rem;
      .wishlist-item-media {
        flex: 32%;
        max-width: 139px;
        @media (max-width: 1023px) {
          min-width: 139px;
          width: 100%;
        }
        figure {
          display: flex;
          margin: 0;
          height: 100%;
          width: 100%;
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }
      .wishlist-item-details {
        align-content: space-between;
        display: flex;
        flex-wrap: wrap;
        flex: calc(68% - 26px);
        padding-left: 26px;
        .wishlist-item-content {
          flex: 100%;
          width: 100%;
          height: -webkit-max-content;
          height: -moz-max-content;
          height: max-content;
          .wishlist-item-price {
            margin-bottom: 15px;
            display: flex;
            &__special,&__old {
              display: inline-block;
              font-family: Spartan;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0;
              line-height: 20px;
              margin: 0;
            }

            &__special {
              color: #222a24;
                padding-right: 10px;
                -webkit-text-decoration-line: none;
                text-decoration-line: none;
            }
            &__old {
              text-decoration-line: line-through;
              color: #9fa6ad;
            }

            @media (max-width: 1023px) {
              p {
                margin: 0;
              }
            }
          }
          .wishlist-item-name {
            color: #d28571;
            font-family: Frank Ruhl Libre;
            font-size: 22px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 32px;
          }
          .wishlist-item-configuration {
            div {
              .sf-property {
                margin-bottom: 5px;
                padding-right: 15px;
                display: inline-block;
                &:last-child {
                  margin-bottom: 5px;
                }

                &__name,
                &__value {
                  color: #222a24;
                  font-family: 'Spartan';
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0;
                }

                &__name {
                  margin-right: -2px;
                }
              }
            }
          }
        }
        .wishlist-item-button {
          display: flex;
          flex-wrap: wrap;
          flex: 100%;
          width: 100%;
        }
      }
    }
  }

  .sf-collected-product__quantity-wrapper {
    position: relative;
    padding: 0;
    flex: calc(70% - 65px);
  }

  .sf-collected-product__quantity-selector {
    border: 1px solid #ddd;
    height: 45px;
    width: 100%;
  }

  .wishlist-item-actions {
    flex: 65px;
    a {
      align-items: center;
      background: #fdcfc1;
      border: 1px solid #fdcfc1;
      cursor: pointer;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      margin-left: -1px;
      width: 48%;
      &:last-child {
        margin-top: -0.25rem;
      }
      svg {
        fill: #fff;
        width: 20px;
        height: 20px;
      }
      img {
        filter: invert(100%) sepia(0%) hue-rotate(38deg) brightness(114%)
          contrast(109%);
        width: 20px;
        height: 20px;
      }
      &:hover,
      &:active,
      &:focus {
        background: #fff;
        border: 1px solid #fff;
        svg {
          fill: #fdcfc1;
        }
        img {
          filter: invert(98%) sepia(86%) saturate(450%) hue-rotate(294deg)
            brightness(101%) contrast(98%);
        }
      }
    }

    .out-of-stock-label {
      margin-right: 1.25rem;
      color: red;
    }

    .wishlist-icon {
      width: 20px;
      height: 20px;
      @include for-mobile {
        width: 16px;
        height: 16px;
      }
    }
  }
  .sf-collected-product__quantity-selector button {
    font-weight: 300;
    flex: 35%;
    padding: 10px 0 0;
    font-size: 18px;
    color: #222a24;
    font-family: 'Spartan';
    line-height: 43px;
  }

  .sf-collected-product__quantity-selector .sf-input {
    flex: 30%;
    color: #222a24;
    font-family: Spartan;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0 0;
  }

  .sf-icon {
    margin: auto;
  }

  .sf-input {
    width: 45px;
    --input-height: 45px;
    padding: 0 5px;
  }
}
</style>
