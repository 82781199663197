var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wishlist"}},[_c('SfSidebar',{staticClass:"sidebar sf-sidebar--right",attrs:{"visible":_vm.isWishlistSidebarOpen,"button":false,"title":" "},on:{"close":_vm.toggleWishlistSidebar},scopedSlots:_vm._u([{key:"circle-icon",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"close-icon",on:{"click":close}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L17 17","stroke":"#171717","stroke-width":"2"}}),_vm._v(" "),_c('path',{attrs:{"d":"M17 1L1 17","stroke":"#171717","stroke-width":"2"}})])])]}},{key:"content-bottom",fn:function(){return [_c('SfButton',{staticClass:"sf-button--full-width color-secondary",on:{"click":_vm.toggleWishlistSidebar}},[_vm._v("\n        "+_vm._s(_vm.$t('Go back shopping'))+"\n      ")])]},proxy:true}])},[_vm._v(" "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.wishlistItems && _vm.wishlistItems.length > 0)?_c('div',{key:"my-wishlist",staticClass:"my-wishlist"},[_c('div',{staticClass:"my-wishlist__total-items"},[_vm._v("\n          "+_vm._s(_vm.$t('Wishlist '))+" ("+_vm._s(_vm.totalItems)+")\n        ")]),_vm._v(" "),_c('div',{staticClass:"collected-product-list"},_vm._l((_vm.wishlistItems),function(wishlistItem,i){return _c('div',{key:i,staticClass:"wishlist-item-list",on:{"click:remove":function($event){return _vm.removeItemFromWishlist({ product: wishlistItem })}}},[_c('div',{staticClass:"wishlist-item-media"},[_c('figure',[_c('img',{attrs:{"src":_vm.wishlistGetters.getItemImage(wishlistItem)
                      ? _vm.wishlistGetters.getItemImage(wishlistItem)
                      : '',"alt":_vm.wishlistGetters.getItemName(wishlistItem)}})])]),_vm._v(" "),_c('div',{staticClass:"wishlist-item-details"},[_c('div',{staticClass:"wishlist-item-content"},[_c('nuxt-link',{attrs:{"to":_vm.localePath(_vm.buildProductUrl(wishlistItem.product))},nativeOn:{"click":function($event){return _vm.toggleWishlistSidebar.apply(null, arguments)}}},[_c('h3',{staticClass:"wishlist-item-name"},[_vm._v("\n                    "+_vm._s(_vm.wishlistGetters.getItemName(wishlistItem))+"\n                  ")])]),_vm._v(" "),(_vm.wishlistGetters.getItemPrice(wishlistItem).special)?_c('div',{staticClass:"wishlist-item-price"},[_c('p',{staticClass:"wishlist-item-price__special"},[_vm._v("\n                    "+_vm._s(_vm.wishlistGetters.getItemPrice(wishlistItem).special &&
                        _vm.$n(
                          _vm.wishlistGetters.getItemPrice(wishlistItem).special,
                          'currency'
                        ))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"wishlist-item-price__old"},[_vm._v("\n                    "+_vm._s(_vm.$n(
                        _vm.wishlistGetters.getItemPrice(wishlistItem).regular,
                        'currency'
                      ))+"\n                  ")])]):_c('div',{staticClass:"wishlist-item-price"},[_c('p',{staticClass:"wishlist-item-price__special"},[_vm._v("\n                    "+_vm._s(_vm.$n(
                        _vm.wishlistGetters.getItemPrice(wishlistItem).regular,
                        'currency'
                      ))+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"wishlist-item-configuration"},[(_vm.isConfigured(wishlistItem))?_c('div',_vm._l((_vm.getProductConfiguration(wishlistItem)),function(option){return _c('SfProperty',{key:option.uid,attrs:{"name":_vm.$t(option.label),"value":option.value}})}),1):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"wishlist-item-button"},[_c('div',{staticClass:"wishlist-item-actions"},[(_vm.canAddToCart(wishlistItem))?_c('a',{on:{"click":function($event){return _vm.addItemToCart(wishlistItem)}}},[_c('SfIcon',[_c('nuxt-img',{attrs:{"width":"20","height":"14","src":require('../assets/images/header/Logo_Carrello.svg'),"alt":"choose product configuration icon"}})],1)],1):(
                      !_vm.isConfigured(wishlistItem) &&
                        _vm.isConfigurable(wishlistItem)
                    )?_c('a',{attrs:{"href":_vm.localePath(_vm.buildProductUrl(wishlistItem.product))}},[_c('SfIcon',[_c('nuxt-img',{attrs:{"width":"20","height":"14","src":"/icons/pencil-icon.svg","alt":"choose product configuration icon"}})],1)],1):_c('span',{staticClass:"out-of-stock-label"},[_vm._v(_vm._s(_vm.$t('Out of stock')))]),_vm._v(" "),_c('a',{on:{"click":function($event){return _vm.removeItemFromWishlist({
                        product: wishlistItem,
                      })}}},[_c('svg',{attrs:{"height":"20","width":"14"}},[_c('rect',{attrs:{"x":"5","y":"1","width":"5","height":"1"}}),_vm._v(" "),_c('rect',{attrs:{"x":"1","y":"2","width":"13","height":"3"}}),_vm._v(" "),_c('rect',{attrs:{"x":"2","y":"6","width":"11","height":"15"}})])])])])])])}),0)]):_c('div',{key:"empty-wishlist",staticClass:"empty-wishlist"},[_c('div',{staticClass:"empty-wishlist__banner"},[_c('nuxt-img',{staticClass:"empty-wishlist__icon",attrs:{"src":require('../assets/images/header/Lazzari-Preferiti.svg'),"alt":"Empty wishlist","format":"webp","width":"211","height":"143","preload":"","loading":"lazy"}}),_vm._v(" "),_c('SfHeading',{staticClass:"empty-wishlist__label",attrs:{"title":_vm.$t('La tua wishlist è vuota'),"description":_vm.$t(
                'Sembra che tu non abbia ancora aggiunto alcun articolo alla tua lista.'
              )}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }