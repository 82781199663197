<template>
  <div
    class="topbar"
    :class="{ 'header--sticky': checkSticky }"
  >
    <p v-if="message">
      {{ message.title }}
      <SfLink
        :link="message.url"
        :target="message.target"
      >
        {{ message.label }}
      </SfLink>
    </p>
  </div>
</template>

<script>
import { computed, ref } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@vue-storefront/gemini';
import {
  SfLink,
} from '@storefront-ui/vue';
import { useI18n } from '../helpers/hooks/usei18n';

export default {
  components: {
    SfLink,
  },
  props: {
    checkSticky: Boolean,
  },
  setup() {
    const { locale } = useI18n();
    const {
      blocks,
    } = useContent(`cmsBlockFooter-${locale}`);
    const preHeaderMessage = `pre-header-${locale}`;
    const message = ref(false);

    return {
      blocks,
      preHeaderMessage,
      message,
    };
  },
  computed: {
    messageHeader() {
      return computed(() => contentGetters.getCmsBlockContent(this.blocks)).value[this.preHeaderMessage];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.message = this.messageHeader;
    });
  },

};
</script>
<style lang="scss" scoped>
  .topbar {
    min-height: 48px;
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 12px 0 6px;
    background: #222a24;
    transition: all .1s;
    width: 100%;
    top: 0;
    @include for-desktop {
      min-height: 35px;
    }

    p,
    a {
      color: #fdcfc1;
      margin: 0;
      font-size: 12px;
      font-family: "Spartan",sans-serif;
      font-weight: var(--font-weight--norm);
    }

    a {
      text-decoration: underline;
      margin-left: 7px;
    }

    &__button {
      margin: 0 0 0 var(--spacer-xs);
    }

    &.header--sticky {
      // transform: translateY(-200px);
    }
  }

  @media (max-width: 768px) {
    .topbar {
      p a {
        width: 100%;
        display: block;
      }
    }
  }
</style>
